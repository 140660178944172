import React from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const EventCalendar = ({ events }) => {
    return (
        <div className="event-calendar">
            <Calendar
                className="calendar"
                tileContent={({ date }) => {
                    const eventOnDate = events.find(event =>
                        new Date(event.startDate).getDate() <= date.getDate() &&
                        new Date(event.endDate).getDate() >= date.getDate()
                    );
                    return eventOnDate ? <p>{eventOnDate.name}</p> : null;
                }}
            />
        </div>
    );
};

export default EventCalendar;
