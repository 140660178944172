import React from 'react';
import '../css/Events.css';

export default function EventItem({ e }) {
    return(
        <div className="event-item-container">
            <div className="event-item-text">
                <span className="event-subtext">{formatDate(e.startDate)} - {formatDate(e.endDate)}</span>
                <br />
                <span className="event-name">{e.name}</span>
                <br />
                <span className="event-subtext">{e.location.city}, {e.location.state}, {e.location.country}</span>
                <br />
            </div>
            <div className="learn-more-button">
                <a 
                    href={e.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ textDecoration: "none", color: "white" }}
                >
                    Learn More
                </a>
            </div>
        </div>
    );
}

// Function to format date without weekdays
function formatDate(dateString) {
    const options = { month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}
