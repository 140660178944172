import React from 'react';
import './css/About.css';

const About = () => {

    return (
        <div className="container">
            <div className="text">
                <h1>About Ken & Bryn</h1>
                <p>
                Ken and Bryn are internationally recognized West Coast Swing champions and winners of the 2023 NASDE tour. Their partnership began 
                in 2017 in Rising Star, an amateur routine division, and transitioned into Classic, a professional category, after they won almost 
                every Rising Star competition on the West Coast Swing circuit. Since then, they have worked their way up to teaching and competing 
                at 30+ West Coast Swing events per year. They are best known for infusing their West Coast Swing with isolations and other forms of 
                hip hop, and for their two most recent two routines, which earned them 4th and 2nd place at The Open Swing Dance Championships.
                </p>
                <h2>Backgrounds</h2>
                <p>
                    Ken and Bryn both had diverse dance backgrounds. Ken grew up dancing hip hop, but fell in love for partner dancing in the 2010s. 
                    He has sampled Houston Two Step, Kizomba, Bachata, and Salsa, and he became semi-professional in DFW Swingout before beginning 
                    West Coast Swing in 2015. 
                </p>
                <p>
                    West Coast Swing is Bryn's first love. She began taking classes with her parents in the San Francisco Bay Area when she was five
                    years old, and she has since supplemented her West Coast Swing with ballet, jazz, contemporary, and hip hop. She also underwent 
                    serious training in international latin (Rumba, Cha Cha, Samba, Paso Doble, and Jive) in her teens to improve her West Coast Swing. 
                </p>
                <p> 
                    Ken and Bryn owe the majority of their West Coast Swing expertise to literally hundreds of 
                    hours of coaching from veterans of the dance. They would like to thank the legends who continue to help them 
                    grow: Mario and Stephanie Robau, Arjay Centeno, Brandi Guild, Kyle Redd, Sarah Van Drake, Victoria Henk,
                    Laurie Schwimmer, Benji Schwimmer, and Robert Royston.
                </p>
            </div>
            <div className="image" >
                <img className="img" src={process.env.PUBLIC_URL + '/GGP2022_SideBySide.jpg'} alt="Sample" />
            </div>
        </div>
    );
};

export default About;
