import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavigationBar from './components/NavigationBar/NavBar';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Events from './components/Events';
// import Services from './components/Services';
import PressKit from './components/PressKit';

function App() {
    return (
      <Router>
      <NavigationBar />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/events" element={<Events />} />
          <Route path="/presskit" element={<PressKit />} />
      </Routes>
      <Footer/>
      </Router>
    );
}

export default App;
