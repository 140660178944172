import React from 'react';
import './css/Home.css';

const Home = () => {
    return (
        <div className="background">
            <div className="text-container"> 
                <h2>Ken & Bryn</h2>
                <p>Ken & Bryn are internationally recognized West Coast Swing champions. Together, they travel the world to teach, perform, and spread their love of the dance.</p>
                <br />
                <a href="/events" className="events-link" > Upcoming Events </a>    
            </div>
        </div>
    );
};

export default Home;
