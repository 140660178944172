// components/Navbar/index.js

import React from "react";
import { Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink, Link } from "./NavbarElements";

const NavBar = () => {
	return (
		<>
			<Nav>
				<Bars />
                <NavBtn>
					<NavBtnLink to="/home">
						Ken & Bryn
					</NavBtnLink>
				</NavBtn>
				<NavMenu>
					<NavLink to="/home" >
						Home
					</NavLink>
					<NavLink to="/about" >
						About
					</NavLink>
					<NavLink to="/events">
						Events
					</NavLink>
					<NavLink to="/presskit">
						Press Kit
					</NavLink>
					<Link to="contact" smooth={true} duration={500} spy={true} exact="true" offset={-80}>
						Follow Us
					</Link>
				</NavMenu>
			</Nav>
		</>
	);
};

export default NavBar;
