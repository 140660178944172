// Footer.js
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome CSS
import styled from "styled-components";

function Footer() {
  return (
    <FooterContainer id="contact">
        <footer style={footerStyle}>
          <div style={iconContainerStyle}>
            {/* <a href="mailto:your@email.com" style={iconStyle}><i className="fas fa-envelope fa-lg"></i></a> */}
            <a href="https://www.facebook.com/kenandbryn/" style={iconStyle} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-lg"></i></a>
            <a href="https://www.instagram.com/kenandbryn/" style={iconStyle} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-lg"></i></a>
            <a href="https://www.tiktok.com/@kenandbryn" style={iconStyle} target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok fa-lg"></i></a>
            <a href="https://www.youtube.com/channel/UCDPPfrCYMXon-KCSRfKymTQ" style={iconStyle} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube fa-lg"></i></a>
          </div>
        </footer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  padding: 15px;
  text-align: center;
`;

const footerStyle = {
    background: '#333',
  };

const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Align icons to the left
};

const iconStyle = {
    margin: '0 25px', // Add margin between icons
    fontSize: '1.4rem', // Increase icon size
    color: "white",
};

export default Footer;
