import React, { useState } from 'react';
import EventList from './eventComponents/EventList';
import EventCalendar from './eventComponents/EventCalendar';
import 'react-datepicker/dist/react-datepicker.css';
import eventData from './eventComponents/eventData';
import './css/Events.css';

const Events = () => {
    // search bar
    // const [search, setSearch] = useState('');
    
    // const filteredEvents = eventData.filter(event => 
    //     event.name.toLowerCase().includes(search.toLowerCase()) ||
    //     (event.location.city + ', ' 
    //         + event.location.state + ', ' 
    //         + event.location.country).toLowerCase().includes(search.toLowerCase())
    // );

    // calendar vs. list view
    const [viewMode, setViewMode] = useState(false);

    return (
        <div>
            {/* <div className="search-controls-container">
                <input
                    type="text"
                    placeholder="Search by name or location"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="search-bar"
                />
                <div className="button-controlls-container">
                <button  className="search-container-button" onClick={() => {setViewMode(!viewMode)}}>{viewMode === false ? "View Calendar" : "View List"}</button>
                </div>
            </div> */}
            <div className="event-page-title">
                <h1>Upcoming Events</h1>
            </div>
            {viewMode === false ? <EventList events={eventData} /> : <EventCalendar events={eventData}/>}
        </div>
    );
};

export default Events;
