const eventData = [
    {
        "name": "Swing Crush",
        "startDate": "2024-02-17T00:00:00.000000Z",
        "endDate": "2024-02-19T00:00:00.000000Z",
        "location": {"city": "San Antonio", "state": "Texas", "country": "United States"},
        "url": "https://satxwestiesproductions.com/"
    },
    {
        "name": "Rose City Swing",
        "startDate": "2024-02-23T00:00:00.000000Z",
        "endDate": "2024-02-26T00:00:00.000000Z",
        "location": {"city": "Portland", "state": "Oregon", "country": "United States"},
        "url": "https://rosecityswing.com/"
    },
    {
        "name": "MADJam",
        "startDate": "2024-02-30T00:00:00.000000Z",
        "endDate": "2024-03-04T00:00:00.000000Z",
        "location": {"city": "Reston", "state": "Virginia", "country": "United States"},
        "url": "https://www.atlanticdancejam.com/"
    },
    {
        "name": "The Chicago Classic",
        "startDate": "2024-03-16T00:00:00.000000Z",
        "endDate": "2024-03-18T00:00:00.000000Z",
        "location": {"city": "Chicago", "state": "Illinois", "country": "United States"},
        "url": "https://thechicagoclassic.com/"
    },
    {
        "name": "Novice Invitational",
        "startDate": "2024-03-23T00:00:00.000000Z",
        "endDate": "2024-03-25T00:00:00.000000Z",
        "location": {"city": "Houston", "state": "Texas", "country": "United States"},
        "url": "https://novice-invitational.com/"
    },
    {
        "name": "The Dutch Open",
        "startDate": "2024-04-05T00:00:00.000000Z",
        "endDate": "2024-04-08T00:00:00.000000Z",
        "location": {"city": "Venray", "state": "North Holland", "country": "Netherlands"},
        "url": "https://www.dutchopenwcs.com/"
    },
    {
        "name": "City of Angels",
        "startDate": "2024-04-12T00:00:00.000000Z",
        "endDate": "2024-04-15T00:00:00.000000Z",
        "location": {"city": "Los Angeles", "state": "California", "country": "United States"},
        "url": "https://www.cityofangelsswing.com/"
    },
    {
        "name": "Workshop Weekend - Minneapolis",
        "startDate": "2024-04-27T00:00:00.000000Z",
        "endDate": "2024-04-29T00:00:00.000000Z",
        "location": {"city": "Minneapolis", "state": "Minnesota", "country": "United States"},
        "url": "https://mwcsdc.com/"
    },
    {
        "name": "Swingsation",
        "startDate": "2024-05-10T00:00:00.000000Z",
        "endDate": "2024-05-13T00:00:00.000000Z",
        "location": {"city": "Surfers Paradise", "state": "Queensland", "country": "Australia"},
        "url": "https://rawconnection.com.au/swingsation/"
    },
    {
        "name": "Grand Nationals",
        "startDate": "2024-05-24T00:00:00.000000Z",
        "endDate": "2024-05-27T00:00:00.000000Z",
        "location": {"city": "Atlanta", "state": "Georgia", "country": "United States"},
        "url": "https://www.usagrandnationals.com/"
    },
    {
        "name": "Workshop Weekend - Kansas City",
        "startDate": "2024-06-01T00:00:00.000000Z",
        "endDate": "2024-06-03T00:00:00.000000Z",
        "location": {"city": "Kansas City", "state": "Kansas", "country": "United States"},
        "url": ""
    },
    {
        "name": "Jack and Jill O'Rama",
        "startDate": "2024-06-07T00:00:00.000000Z",
        "endDate": "2024-06-10T00:00:00.000000Z",
        "location": {"city": "Anaheim", "state": "California", "country": "United States"},
        "url": "https://jackandjillorama.com/"
    },
    {
        "name": "Liberty Swing Dance Championships",
        "startDate": "2024-06-28T00:00:00.000000Z",
        "endDate": "2024-07-01T00:00:00.000000Z",
        "location": {"city": "New Brunswick", "state": "New Jersey", "country": "United States"},
        "url": "https://libertyswing.com/"
    },
    {
        "name": "Wild Wild Westie",
        "startDate": "2024-07-05T00:00:00.000000Z",
        "endDate": "2024-07-08T00:00:00.000000Z",
        "location": {"city": "Dallas", "state": "Texas", "country": "United States"},
        "url": "https://www.wildwildwestie.com/"
    },
    {
        "name": "Midwest Westiefest",
        "startDate": "2024-07-20T00:00:00.000000Z",
        "endDate": "2024-07-22T00:00:00.000000Z",
        "location": {"city": "Kansas City", "state": "Kansas", "country": "United States"},
        "url": "https://www.midwestwestiefest.com/"
    },
    {
        "name": "Swingtacular: The Galactic Open",
        "startDate": "2024-08-02T00:00:00.000000Z",
        "endDate": "2024-08-05T00:00:00.000000Z",
        "location": {"city": "Burlingame", "state": "California", "country": "United States"},
        "url": "https://www.dancegeekproductions.art/2023-swingtacular.html"
    },
    // {
    //     "name": "SwingTime Denver",
    //     "startDate": "2024-08-15T00:00:00.000000Z",
    //     "endDate": "2024-08-18T00:00:00.000000Z",
    //     "location": {"city": "Denver", "state": "Colorado", "country": "United States"},
    //     "url": "http://www.swingtimewcs.com"
    // },
    {
        "name": "Summer Hummer",
        "startDate": "2024-08-23T00:00:00.000000Z",
        "endDate": "2024-08-26T00:00:00.000000Z",
        "location": {"city": "Woburn", "state": "Massachusetts", "country": "United States"},
        "url": "https://www.summerhummerboston.com"
    },
    {
        "name": "Desert City Swing",
        "startDate": "2024-08-30T00:00:00.000000Z",
        "endDate": "2024-09-02T00:00:00.000000Z",
        "location": {"city": "Phoenix", "state": "Arizona", "country": "United States"},
        "url": "https://desertcityswing.com/"
    },
    {
        "name": "Workshop Weekend - Albany",
        "startDate": "2024-09-13T00:00:00.000000Z",
        "endDate": "2024-09-16T00:00:00.000000Z",
        "location": {"city": "Albany", "state": "New York", "country": "United States"},
        "url": ""
    },
    {
        "name": "Korea Westival",
        "startDate": "2024-09-27T00:00:00.000000Z",
        "endDate": "2024-09-30T00:00:00.000000Z",
        "location": {"city": "Seoul", "state": "Gyeonggi", "country": "South Korea"},
        "url": "https://www.facebook.com/koreawestival/"
    },
    {
        "name": "Boogie by the Bay",
        "startDate": "2024-10-04T00:00:00.000000Z",
        "endDate": "2024-10-07T00:00:00.000000Z",
        "location": {"city": "Burlingame", "state": "California", "country": "United States"},
        "url": "https://boogiebythebay.com/"
    },
    {
        "name": "Halloween Swing Thing",
        "startDate": "2024-10-25T00:00:00.000000Z",
        "endDate": "2024-10-28T00:00:00.000000Z",
        "location": {"city": "Los Angeles", "state": "California", "country": "United States"},
        "url": "https://www.hstswing.com/"
    },
    {
        "name": "Tampa Bay Classic",
        "startDate": "2024-11-09T00:00:00.000000Z",
        "endDate": "2024-11-11T00:00:00.000000Z",
        "location": {"city": "Tampa Bay", "state": "Florida", "country": "United States"},
        "url": "https://www.facebook.com/Tampabayclassic/"
    },
    {
        "name": "DCSX - D.C. Swing Experience",
        "startDate": "2024-11-15T00:00:00.000000Z",
        "endDate": "2024-11-18T00:00:00.000000Z",
        "location": {"city": "Herndon", "state": "Virginia", "country": "United States"},
        "url": "https://dcswingexperience.com/"
    },
    {
        "name": "The Open Swing Dance Championships",
        "startDate": "2024-11-27T00:00:00.000000Z",
        "endDate": "2024-12-02T00:00:00.000000Z",
        "location": {"city": "Burbank", "state": "California", "country": "United States"},
        "url": "https://theopenswing.com/"
    },
    {
        "name": "TAP - The After Party",
        "startDate": "2024-12-06T00:00:00.000000Z",
        "endDate": "2024-12-09T00:00:00.000000Z",
        "location": {"city": "Burbank", "state": "California", "country": "United States"},
        "url": "https://tapwcs.com/"
    },
    {
        "name": "The Global Grand Prix",
        "startDate": "2024-12-13T00:00:00.000000Z",
        "endDate": "2024-12-16T00:00:00.000000Z",
        "location": {"city": "Toulouse", "state": "Occitania", "country": "France"},
        "url": "https://www.globalgrandprixwcs.com/"
    },
    {
        "name": "SwingVester",
        "startDate": "2024-12-28T00:00:00.000000Z",
        "endDate": "2024-12-30T00:00:00.000000Z",
        "location": {"city": "Wels", "state": "Upper Austria", "country": "Austria"},
        "url": "https://www.swingvester.com/"
    },
    {
        "name": "Countdown Swing",
        "startDate": "2025-01-03T00:00:00.000000Z",
        "endDate": "2025-01-06T00:00:00.000000Z",
        "location": {"city": "Framingham", "state": "Massachusetts", "country": "United States"},
        "url": "https://countdownswingboston.com/"
    },
];

export default eventData;