import React from 'react';
import "./css/PressKit.css";

const PressKit = () => {
  return (
    <div className="press-kit-container">
      <h1 className="page-title">Press Kit</h1>

      <section>
        <div className="image-grid">
          {/* Example image */}
          <a href={process.env.PUBLIC_URL + '/pressKit/GGP22_OhOh.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/GGP22_OhOh.jpg'} alt="2022 routine shot" />
          </a>

          <a href={process.env.PUBLIC_URL + '/pressKit/Open23_BackEnd.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/Open23_BackEnd.jpg'} alt="2023 routine shot" />
          </a>

          <a href={process.env.PUBLIC_URL + '/pressKit/DiscGreen_Dock.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/DiscGreen_Dock.jpg'} alt="social dance on dock" />
          </a>

          <a href={process.env.PUBLIC_URL + '/pressKit/Open23_BackEnd.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/disc_green_houston_heads.jpg'} alt="Houston headshot" />
          </a>

          <a href={process.env.PUBLIC_URL + '/pressKit/DiscGreen_KP.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/DiscGreen_KP.jpg'} alt="Ken closeup, Bryn in background" />
          </a>

          <a href={process.env.PUBLIC_URL + '/pressKit/DiscGreen_Bryn.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/DiscGreen_Bryn.jpg'} alt="Bryn closeup, Ken in backgroun" />
          </a>

          <a href={process.env.PUBLIC_URL + '/pressKit/DiscGreen_KPDock.jpg'} download>
            <img src={process.env.PUBLIC_URL + '/pressKit/DiscGreen_KPDock.jpg'} alt="social dance on dock featuring Ken" />
          </a>
        </div>
      </section>

      <section className="bio-section">
        <h2>Short Bio</h2>
        <p className="bio-text">
            Ken and Bryn began their partnership in Rising Star in 2017. They have since become NASDE champions and 
            podiumed twice in Classic at the Open Swing Dance Championships (4th and 2nd places). They have gained 
            a reputation for incorporating isolations and other forms of hip hop into their west coast swing, and 
            they enjoy introducing westies to these styles alongside west coast swing fundamentals around the world.
        </p>
      </section>

      <section className="bio-section">
        <h2>Long Bio</h2>
        <div className="bio-text">  
            <p>
                Ken and Bryn have been partners for seven years. During that time, they have moved from Rising 
                Star to Classic and become NASDE champions, placing 2nd at the Open Swing Dance Championships last 
                year. As routine dancers, they are known for delivering crowd-energizing pieces and exploring the 
                intersection between west coast swing and hip hop. As social dancers and teachers, they seek to marry 
                old school concepts with their creative pursuits and to inspire new dancers to appreciate the 
                foundations of the dance like they do.
            </p>
            <p>
                Both Ken and Bryn have diverse dance backgrounds. Ken grew up dancing hip hop but fell in love with 
                partner dancing in the 2010s. He has sampled bachata, salsa, kizomba, and Houston two step, and 
                became semi-professional in DFW swingout before turning to west coast swing in 2015. Thanks to his 
                excellent coaches and work ethic, he entered his first Champions jack and jill in 2019, four years 
                after his first Novice competition.
            </p>
            <p>
                West coast swing was Bryn's first love: she began social dancing with her parents in Northern 
                California at five years old and became a serious competitor in her teens. She has supplemented 
                her west coast with contemporary, jazz, ballet, and international latin (cha cha, samba, rumba, 
                paso doble, and jive), which she danced at the open gold level. She earned her first Champions 
                points at seventeen and officially moved into the division after placing top three in the NASDE 
                All Stars Jack and Jill tour.
            </p>
        </div>
      </section>
    </div>
  );
};

export default PressKit;
