// components/Navbar/navbarElements.js

import { FaBars } from "react-icons/fa";
import { NavLink as NLink } from "react-router-dom";
import styled from "styled-components";
import { Link as SLink } from "react-scroll";

{/* <FontAwesomeIcon icon="fa-duotone fa-x" /> */}

export const Link = styled(SLink)`
	color: #808080;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		color: #C0C0C0;
	}
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const Nav = styled.nav`
	position: fixed;
	top: 0;
	width: 100%;
	background: #333;
	height: 45px;
	display: flex;
	justify-content: space-between;
	// padding: 0.2rem calc((100vw - 1000px) / 2);
	z-index: 1000;
`;

export const NavLink = styled(NLink)`
	color: #808080;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		color: #C0C0C0;
	}
	// @media screen and (max-width: 768px) {
	// 	display: none;
	// }
`;

export const Bars = styled(FaBars)`
	display: none;
	color: #808080;
	// @media screen and (max-width: 768px) {
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	transform: translate(-100%, 75%);
	// 	font-size: 1.8rem;
	// 	cursor: pointer;
	// }
`;



export const NavMenu = styled.div`
	display: flex;
	align-items: center;
	margin-right: 10px;
	white-space: nowrap; */
	// @media screen and (max-width: 768px) {
	// 	display: none;
	// }
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 24px;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavBtnLink = styled(NLink)`
	border-radius: 4px;
	background: #333;
	padding: 10px 10px;
	color: #FFFFFF;
	font-weight: bold; /* Add this line to make the text bold */
	// font-size: 16px: 
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	/* Second Nav */
	margin-left: 20px;
	// &:hover {
	// 	transition: all 0.2s ease-in-out;
	// 	background: #fff;
	// 	color: #808080;
	// }
`;

export const DropDownMedu = styled.div`
	display: none;
	// @media screen and (max-width: 768px) {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: flex-start;
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	width: 100%;
	// 	background: #333;
	// 	pading: 10px 0;
	// 	z-index: 100;
	// }
`;
	
